<template>
<div>
    <el-card>
        <nav class="input" style="width: auto">
            <el-input v-model="search.keyword" size="medium" placeholder="企业名称" />
        </nav>
        <nav class="input" style="width: 150px">
            <el-button size="small" @click="reset">重置</el-button>
            <el-button type="primary" size="small" @click="subimt">{{
            '搜索'
          }}</el-button>
        </nav>

    </el-card>

    <el-card style="margin-top: 10px">
        <el-table :data="list" style="width: 100%" border stripe :header-cell-style="rowClass">
            <el-table-column label="ID" prop="id">

            </el-table-column>

            <el-table-column label="logo&官网">
                <template #default="{row}">
                    <p-image size="80px" :src="row.logo"></p-image>
                    <p>
              <a
                v-if="row.website"
                :href="
                  row.website.includes('http')
                    ? row.website
                    : 'http://' + row.website
                "
                target="_blank"
                >（查看官网）</a
              >
            </p>
                </template>
            </el-table-column>
          <el-table-column label="背景图">
            <template #default="{row}">
              <p-image v-if="row.backgroundImage" size="80px" :src="row.backgroundImage"></p-image>
            </template>
          </el-table-column>
            <el-table-column label="企业简称" prop="name">
            </el-table-column>
            <el-table-column label="企业全称" prop="fullName">
            </el-table-column>
          <el-table-column label="slogan" prop="slogan">
          </el-table-column>
            <el-table-column label="公司简介" prop="companyDesc" :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column label="地址">
                <template slot-scope="{row}">
                    {{row.provinceName}}{{row.cityName}}{{row.address}}
                </template>
            </el-table-column>
             <el-table-column label="类型行业">
                <template slot-scope="{row}">
                    <p>{{row.typeName.toString()}}</p>
                    <p>{{row.businessName.toString()}}</p>
                    <p>{{row.subBusinessName.toString()}}</p>
                </template>
            </el-table-column>
            <el-table-column label="公司规模">
                <template #default="{row}">
                    {{row.peopleName}}
                </template>
            </el-table-column>
            <el-table-column label="融资情况" prop="financingName">
            </el-table-column>
          <el-table-column label="企业承诺">
            <template #default="{row}">
              <div v-for = "item in commitmentChange(row.commitment)" :key="item">{{item}}</div>
            </template>
          </el-table-column>
            <el-table-column label="状态">
                <template slot-scope="{row}">
                    <el-tag :type="row.status==4?'danger':'warning'">{{row.status | fnStatus}}</el-tag>
                    <div style="margin-top: 5px;" v-if="row.status==4">
                        {{row.remark}}
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="提交时间" prop="updateTime"></el-table-column>
            <el-table-column label="操作">
                <template slot-scope="{row}">
                    <el-button type="text" size="mini" style="color:rgb(249, 133, 106)" icon="el-icon-camera-solid" @click="$toUrl(`${baseUrl}enterprise/${row.enterpriseId}.html`)">查看企业详情</el-button>
                    <el-button type="text" icon="el-icon-edit" size="mini" class="skyblue" @click="audit(row)">审核通过</el-button>
                    <el-button type="text" icon="el-icon-edit" size="mini" style="color:red" @click="audit_f(row)">审核不通过</el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination :current-page="search.page" background :page-sizes="[3, 5, 10, 15]" :page-size="search.pageSize" layout="total, prev, pager, next" :total="total" @current-change="handleCurrentChange" />
    </el-card>
    <failing :type="2" :dialog-visible.sync="p_a" custom @submit="subFail" :form="p_a_f"  @getlist="getlist" />
    <pop ref="pop" @toAudit="toAudit" title="请确认数据库是否有该用户认证的企业">
        <el-form :model="form" label-width="auto">
            <el-form-item>
                <!-- <el-input v-model="form.enterpriseId"></el-input> -->
                <fuzzy-selection type="1" all tipName="确认库里没有该企业可以不填。" clearable :value.sync="form.enterpriseId"></fuzzy-selection>
            </el-form-item>
            <div class="tip waring">
                该企业不是通过认领提交的，请再次确认我们库里是否有该企业，否则会造成企业数据冗余。
            </div>
        </el-form>
    </pop>
</div>
</template>

<script>
import list from '/src/mixin/list.js'
import fuzzySelection from '@/components/fuzzySelection'
export default {
    mixins: [list],
    components: {
        failing: () => import('../enterprise_cert_list/failing.vue'),
        fuzzySelection,
    },
    data() {
        return {
            search: {
                page: 1,
                pageSize: 10,
                keyword: '',
            },
            p_a: false,
            p_a_f: null,
            form: {
                enterpriseId: ''
            }
        }
    },
    filters: {
        fnStatus: function(value) {
          if(value == 2){
              return '提交待审核'
          }
          if(value == 3){
              return '审核通过'
          }
          if(value == 4){
              return '审核不通过'
          }
        }
    },
    created() {
        this.getlist();
    },
    methods: {
      commitmentChange(data){
        if(data.length){
          return JSON.parse(data)
        }

      },

        add() {},
        async toAudit(row) {
            const {
                data: res
            } = await this.$http.post('/admin/Enterprise/cert', {
                id: row.id,
                status: 1,
                enterpriseId: this.form.enterpriseId
            })
            if (res.errorCode == 200) {
                this.$message.success(res.message)
                this.$refs.pop.show = false
                this.getlist()
            }
        },
        async subFail(val){
            const {data:res} = await this.$http.post('/admin/Enterprise/enterpriseEditCheck',{
                id:val.id,
                status:4,
                remark:val.remark
            })
            if(res.errorCode==200){
                this.$message.success(res.message)
                this.p_a=false
                this.getlist()
            }
        },
         async audit(row) {
            await this.$store.dispatch('$confirm')
             const {data:res} = await this.$http.post('/admin/Enterprise/enterpriseEditCheck',{
                id:row.id,
                status:3
            })
            if(res.errorCode==200){
                this.$message.success(res.message)
                this.getlist()
            }
            
        },
        audit_f(row) {
            // console.log(row);
            
            this.p_a = true
            this.p_a_f = row
            // const {data:res} =  await this.$http.post('/admin/Enterprise/cert',{
            //        id:row.id,
            //        status:2
            //    })
        },
        async getlist() {
            const {
                data
            } = await this.$http.get('/admin/Enterprise/getEditCheckList', {
                params: {
                    ...this.search,
                }
            })
            this.list = data.data.list
            this.total = data.data.totalCount
        },
        subimt() {
            this.search.page=1
            this.getlist()
        }
    },
    computed: {
        baseUrl() {
            return process.env.VUE_APP_BASE_URL
        }
    },
}
</script>

<style lang="less" scoped>

</style>
